@import "@ashishdotme/ui/styles/_theme.sass";

.hero {
  background: #654ea3;
  background: -webkit-linear-gradient(to right, #eaafc8, #654ea3);
  background: linear-gradient(to right, #eaafc8, #654ea3);
}

.card {
  border-radius: 5px
}

@media screen and (min-width: 1472px) {
  .container {
    max-width: 1344px;
    width: 1344px;
  }
}
